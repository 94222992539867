;
(function ($) {
    $(document).ready(function () {
        $('.header-wrapper')[$(window).scrollTop() > 180 ? 'addClass' : 'removeClass']('sticky');
        if ($('.summary-block').length) {
            var summaryHeight = $('.summary-block').height();

            var booleanSticky = $(window).scrollTop() > ($('.summary-block').offset().top + 100);
            $('.summary-block')[booleanSticky ? 'addClass' : 'removeClass']('sticky');

            $('.summary-block .collapse-menu li a').on('click', function() {
                $('.summary-block').removeClass('closed');
                $('.collapse-menu').removeClass('show');
            });

            $('.summary-block').parent('.bb-content').height(summaryHeight);

            if (!booleanSticky) {
                $('.summary-block .btn, .summary-block .btn-close, .summary-block .collapse-menu li a').on('click', function() {
                    $('.summary-block').toggleClass('closed');
                   
                    if ($('.summary-block').hasClass('closed')) {
                        $('.summary-block').closest('.cloudcontentset').next('.cloudcontentset').css('margin-top', '- (summaryHeight - 20)px');
                    } else {
                        $('.summary-block').closest('.cloudcontentset').next('.cloudcontentset').css('margin-top', '0');
                    }
                });

            }
        }

        lightbox.option({
            disableScrolling: true,
            wrapAround: true,
            fadeDuration: 0,
            imageFadeDUration: 0,
            resizeDuration: 0,
            albumLabel: '%1 sur %2'
        })
        //END ready
    });

    // Window Resize
    $(window).resize(function () {
        // End window Resize
    });

    // Window Scroll
    $(window).on('scroll', function() {
        $('.header-wrapper')[$(window).scrollTop() > 180 ? 'addClass' : 'removeClass']('sticky');
        if ($('.summary-block').length) {
            var booleanSticky = $(window).scrollTop() > ($('.summary-block').offset().top + 100);

            $('.summary-block')[booleanSticky ? 'addClass' : 'removeClass']('sticky');

            $('.summary-block:not(.closed)').find('.collapse-menu')[booleanSticky ? 'removeClass' : 'addClass']('show');
        }
    });
})(jQuery);
